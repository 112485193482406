<template>
  <div class="ct-table">
    <b-table
      v-if="loaded"
      ref="btable"
      v-bind="$attrs"
      show-empty
      selected-variant="none"
      :tbody-class="tbodyClass"
      :thead-class="theadClass + (hideHeaders ? ' d-none' : '')"
      :sticky-header="stickyHeader"
      :small="smallState"
      :stacked="stackedState"
      :responsive="responsive"
      :fields="tableDefinition"
      :items="items"
      :per-page="totalRecords ? 0 : resultsPerPage"
      :current-page="currentPage"
      :selectable="selectable"
      :select-mode="selectMode"
      :no-local-sorting="noLocalSorting"
      v-on="$listeners"
      @row-selected="onRowSelected"
      @head-clicked="onHeadClicked"
      @row-clicked="onRowClicked"
    >
      <template slot="head(selected)" slot-scope="data">
        <b-form-checkbox
          v-if="showSelectAllCheckbox"
          v-model="isSelectAllChecked"
          :cy-data="`select-${data.index}`"
          :indeterminate.sync="isSelectAllIndeterminate"
          @input="handleSelectAllCheckbox($event, data.index)"
        />
      </template>

      <template slot="cell(selected)" slot-scope="data">
        <b-form-checkbox
          v-if="selectableFunction(data.item)"
          v-model="data.rowSelected"
          @input="handleRowCheckbox($event, data.index)"
        />

        <div v-else>
          <slot
            name="selectable-alt-content"
            v-bind="data"
          />
        </div>
      </template>

      <!-- The 'expanded-row-details' wrapper slot provides 'row-details' with a targetable class and ref. -->
      <!-- NOTE: No `cell(__slot_name__)` syntax for 'expanded-row-details' slot. -->
      <template slot="row-details" slot-scope="slotData">
        <div ref="showDetails" :class="showDetailsClasses">
          <slot
            name="expanded-row-details"
            v-bind="slotData"
          />
        </div>
      </template>

      <template v-for="(_, name, index) in $scopedSlots" :slot="name" slot-scope="slotData">
        <div :key="index" class="expandable-row">
          <div v-if="expandable && [0,1].includes(index) && expandableFunction(slotData.item)" class="caret">
            <span />
          </div>
          <slot
            :name="name"
            v-bind="slotData"
          />
        </div>
      </template>
    </b-table>
    <b-row
      v-if="isPaginated && loaded && items && (currentPage > 0 || items.length > (perPage - 1))"
      class="paginator"
    >
      <c-pagination
        :items="items"
        :current-page.sync="currentPage"
        :per-page="resultsPerPage"
        :total-records="totalRecords"
      />
      <b-form-group>
        <b-form-select
          v-if="items && items.length > 0"
          id="perPageSelect"
          v-model="resultsPerPage"
          size="sm"
          class="my-0"
          align="fill"
          :options="pageOptions"
        />
      </b-form-group>
    </b-row>
    <ct-centered-spinner v-if="!loaded" />
  </div>
</template>

<script>
/*

# Table
This is a wrapper for the B-Table component with some additional functionality, such as pagination,
a loading spinner, and cleaner multi-select functionality.
Reference: https://bootstrap-vue.js.org/docs/components/table/#comp-ref-b-table

## Usage Example
  <ct-table
    :loaded="loaded"
    :fields="tableDefinition"
    :items="searchResults"
  >
  tableDefinition:
    { key: 'jurisdiction', label: 'Jurisdiction', sortable: false },
    { key: 'ruleDescription', label: 'Type of Filing', sortable: true },
    { key: 'actions', label: 'Actions', sortable: false },
  ],

## Events
  selection-changed
  row-selected
  head-clicked
  pagination-changed
  row-collapsed

## Methods
  selectAllRows
  clearSelected
*/

  import CPagination from './Pagination'
  import CtCenteredSpinner from './CtCenteredSpinner'

  export default {
  name: 'CtTable',
  components: { CtCenteredSpinner, CPagination },
  props: {
    fields: {
      type: [Array, Boolean],
      default: false,
    },
    items: {
      type: [Array, Boolean],
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    expandableFunction: {
      type: Function,
      default: function() {
        return true
      },
    },
    // Automatically expand one or more rows on component load
    expandAuto: {
      type: Boolean,
      default: false,
    },
    // Allow expanding of more than one row at a time
    expandMultiple: {
      type: Boolean,
      default: false,
    },
    noLocalSorting:{
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: 'multi',
    },
    allElementsSelectable: {
      type: Boolean,
      default: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: [String, Boolean],
      default: '570px',
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    isSelectAllChecked: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: undefined,
    },
    hideHeaders: {
      type: Boolean,
      default: false,
    },
    tbodyClass: {
      type: String,
      default: "",
    },
    theadClass: {
      type: String,
      default: "",
    },
    selectableFunction: {
      type: Function,
      required: false,
      default: function() {
        return true
      },
    },
    autoExpandCell: {
      type: Function,
      required: false,
      default: function() {
        return false
      },
    },
    pageOptions: {
      type: Array,
      default: () => [
        { value: 25, text: '25 per page' },
        { value: 50, text: '50 per page' },
        { value: 100, text: '100 per page' },
        { value: 200, text: '200 per page' },
        { value: 500, text: '500 per page' },
        { value: 1000, text: '1000 per page' },
        { value: 10000, text: '10000 per page' },
      ],
    },
  },
  data() {
    return {
      resultsPerPage: this.isPaginated ? this.perPage : 25,
      tableDefinition: this.fields,
      currentPage: 1,
      selected: [],
      isSelectAllIndeterminate: false,
      showDetailsClasses: this.expandAuto ? 'show-details expanded' : 'show-details',
    }
  },
  computed: {
    showSelectAllCheckbox() {
      return this.allElementsSelectable
    },
    stackedState() {
      return this.stacked !== undefined ? this.stacked : this.$mq === 'sm'
    },
    smallState() {
      return this.small !== undefined ? this.small : this.$mq !== 'lg'
    },
  },
  watch: {
    selected: function(newSelection) {
      // This is the cleanest and most reliable way to update the state of the selectAll checkbox.
      if (newSelection.length === 0) {
        this.isSelectAllIndeterminate = false
        this.isSelectAllChecked = false
      } else if (newSelection.length === this.resultsPerPage) {
        this.isSelectAllIndeterminate = false
        this.isSelectAllChecked = true
      } else {
        this.isSelectAllIndeterminate = true
      }

      this.$emit('selection-changed', newSelection)
    },
    currentPage() {
      this.paginationChanged()
    },
    resultsPerPage() {
      this.resultsPerPageChanged()
    },
  },
  mounted() {
    if (this.selectable) {
      this.tableDefinition.unshift({ key: 'selected', sortable: false })
    }
  },
  updated() {
    if (this.items && this.autoExpandCell(this.items[0])) {
      this.expandRow(this.items[0])
    }
  },
  methods: {
    onRowSelected(data) {
      let newData = []
      for (const item of data) {
        if (this.selectableFunction(item)) {
          newData.push(item)
        }
      }
      this.$emit('row-selected', newData)
      this.selected = newData
    },
    onHeadClicked(data) {
      this.$emit('head-clicked', data)
    },
    handleSelectAllCheckbox() {
      if (this.isSelectAllChecked) {
        this.selectAllRows()
      } else {
        this.clearSelected()
      }
    },
    selectAllRows() {
      this.$refs.btable.selectAllRows()
    },
    clearSelected() {
      this.$refs.btable.clearSelected()
    },
    handleRowCheckbox(checkboxState, rowIndex) {
      if (checkboxState) {
        this.$refs.btable.selectRow(rowIndex)
      } else {
        this.$refs.btable.unselectRow(rowIndex)
      }
    },
    onRowClicked(item, _index, _event) { // eslint-disable-line no-unused-vars
      if (this.expandable && this.expandableFunction(item)){
        item._showDetails
        ? this.showDetails(item)
        : this.noDetails(item)
      }
    },
    showDetails(item) {
      this.$emit('row-collapsed', item)
      this.clearSelected()
      this.collapseRow(item)
    },
    noDetails(item) {
      if (!this.expandMultiple) {
        this.items.forEach(item => item._showDetails = false)
        this.showDetailsClasses = 'show-details'
      }
      this.expandRow(item)
    },
    collapseRow(item) {
      setTimeout(function () {
        item._showDetails = false
      }.bind(this), 800)
      if (!this.expandMultiple) { this.showDetailsClasses = 'show-details' }
    },
    expandRow(item) {
      item._showDetails = true
      setTimeout(function () {
        this.showDetailsClasses = 'show-details expanded'
      }.bind(this), 1)
    },
    paginationChanged() {
      this.clearSelected()
      this.$emit('pagination-changed', {
        currentPage: this.currentPage,
        perPage: this.resultsPerPage,
      })
    },
    resultsPerPageChanged() {
      // need to reset the current page to avoid going beyond the result set with too large of an offset
      this.currentPage = 1
      this.paginationChanged()
    },
  },
}
</script>
<style lang="scss">
  .ct-table {
    #services-requiring-attention {
      td, th {
        div.custom-control {
          display: none;
        }
      }
    }

    .paginator {
      display: flex;
      justify-content: center;

      > .form-group {
        margin: auto 1rem;
      }

      .page-link {
        padding: 0 0.75rem;
      }
    }

    .pagination.b-pagination .page-item.active .page-link {
      background: #fff;
      color: #000;
      border-bottom: #000 solid 1px !important;
    }

    .pagination.b-pagination {
      .page-item:last-of-type,
      .page-item:first-of-type, {
        font-size: 2rem;

        button {
          padding-bottom: .375rem;
        }
      }
    }

    .b-table-selectable th:nth-child(1) div:not(.custom-checkbox) {
      display: none;
    }

    @media only screen and (min-width: 768px) {

      tbody > tr[role=row] {
        cursor: pointer;
      }

      .b-table-has-details {
        padding-right: 10px !important;
        font-weight: 600;
        td {
          &:first-of-type {
            border-top-left-radius: 4px;
            border-left: solid $active-border-color 1px;
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-right: solid $active-border-color 1px;
          }
          border-top: solid $active-border-color 1px;
        }
      }

      .b-table-details > td {
        padding: 0px !important;
      }

      div.show-details.expanded {
        div.show-details.expanded {
          td {
            border-top: solid $subtle-border-color 1px !important;
          }
          /* Remove margin below inner tables to save space in nested tables. */
          .table-responsive {
            margin-bottom: 0px;
          }
        }
      }

      .show-details {
        border-top: none !important;
        border-left: solid $active-border-color 1px;
        border-right: solid $active-border-color 1px;
        border-bottom: solid $active-border-color 1px;

        box-shadow: $default-border-color 0px 16px 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        transition: all .8s ease-in-out;

        overflow: hidden;
        max-height: 0px;

        &.expanded {
          overflow: unset;
          max-height: 100000px;
        }
      }

      // For tables within tables, make styles subtler
      // so as not to compete with the parent table's styles.
      table {
        border-spacing: 0px;
        border-collapse: separate;

        table {
          .b-table-has-details td {
            border-radius: 0px !important;
            border: none !important;
            border-top: solid $default-border-color 4px !important;
            border-bottom: none !important;
          }
          .show-details {
            border-radius: 0px !important;
            box-shadow: none;
            border: none;
            border-top: solid $default-border-color 1px;
            border-bottom: solid $default-border-color 4px !important;
          }
          th[role=columnheader],
          table th[role=columnheader] {
            border-bottom: none !important;
            border-top: solid $default-border-color 1px !important;
          }
        }
      }

      table {
        .expandable-row {
          padding-left: 20px;
        }

        table {
          // Don't left-pad nested tables
          .expandable-row {
            padding-left: initial;
          }
        }
      }
    }

    // ========== Carets ==========
    .caret {
      position: relative;
    }

    .caret span {
      transition: all .2s ease-in-out;
      width: 0;
      height: 0;
      display: inline-block;
      border: 6px solid transparent;
      position: absolute;
      top: 6px;
      left: -22px;
      border-left-color: black;
    }

    .b-table-has-details {
      .caret span {
        transform: rotate(90deg);
        top: 9px;
        left: -24px;
      }
    }

    // For compressed table view
    @media only screen and (max-width: 769px) {
      .caret {
        position: relative;
      }

      .caret span {
        top: 6px;
        left: -11px;
      }

      .b-table-has-details {
        .caret span {
          transform: rotate(90deg);
          top: 9px;
          left: -13px;
        }
      }
    }
  }

</style>
